import React, { useState } from "react";
import {
    Container,
    Box,
    Grid,
    Typography,
    Switch,
    Button,
    IconButton,
    Stack,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider, TimePicker, DatePicker } from "@mui/x-date-pickers";
import MDBox from "../../components/MDBox";
import Swal from "sweetalert2";
import {Col, Row} from "reactstrap";
import {FormattedMessage, useIntl} from "react-intl";
import FormField from "./account/components/FormField";
import MDInput from "../../components/MDInput";
import CreatableSelect from "react-select/creatable";
import {useAuth} from "../../useAuth";
import _ from "lodash";
import {API} from "../../api";
import moment from "moment";
import {v4} from "uuid";
import Select from "react-select";
import Table from "@mui/material/Table";
import MDButton from "../../components/MDButton";

const Toast = Swal.mixin({
    toast: true,
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
    }
});

const api = new API()


const initialForm = {
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().add(3, 'month').format('YYYY-MM-DD'),
    timeStart: 6,
    timeEnd: 23,
    recurring: false,
    recurringWeekAmount: 1,
    maxUsers: 1,
    activity: null,
    location: null,
    employee: null,
    price: null,
}

const WeeklyHours = () => {

    const intl = useIntl();
    const {user, fetchUser} = useAuth();
    const [activeStep, setActiveStep] = useState(0);
    initialForm.employee = user.uuid;
    const [form, setForm] = useState(_.cloneDeep(initialForm));
    const [isLoading, setIsLoading] = useState(false);

    const createOption = (label, val = null) => ({
        label,
        value: val || v4(),
    });
    const toHours = (v) => v.toString().padStart(2, '0')+":00"
    const rangeOptions = (start, end) => {
        return _.range(start, end+1).map(a => ({
            label: toHours(a),
            value: a
        })) || []
    }
    const [options, setOptions] = useState({
        activities: user?.dicts?.sports?.map(a=>({
            label: a.name[intl.locale],
            value: a.key
        })) || [],
        employees: user?.coachData?.employees?.map(a=>({
            label: a.name,
            value: a.id
        })) || [],
        locations: user?.coachData?.locations?.map(a=>({
            label: a.name,
            value: a.id
        })) || [],
        startTimes: rangeOptions(6, 22),
        endTimes: rangeOptions(8, 23),
        maxUsers: _.range(1, 31).map(a => ({
            label: a,
            value: a
        })) || [],
    });

    const days = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
    const [availability, setAvailability] = useState({
        MON: [
            { start: new Date().setHours(9, 0), end: new Date().setHours(17, 0) },
        ],
        TUE: [
            { start: new Date().setHours(9, 0), end: new Date().setHours(17, 0) },
            { start: new Date().setHours(18, 0), end: new Date().setHours(19, 0) },
        ],
        WED: [
            { start: new Date().setHours(9, 0), end: new Date().setHours(17, 0) },
        ],
        THU: [
            { start: new Date().setHours(9, 0), end: new Date().setHours(17, 0) },
        ],
        FRI: [
            { start: new Date().setHours(9, 0), end: new Date().setHours(17, 0) },
        ],
        SAT: [],
        SUN: [],
    });

    const handleSwitchChange = (day) => {
        const newAvailability = { ...availability };
        if (newAvailability[day].length > 0) {
            newAvailability[day] = [];
        } else {
            newAvailability[day] = [{ start: new Date(), end: new Date() }];
        }
        setAvailability(newAvailability);
    };

    const handleTimeChange = (day, index, type, value) => {
        if (!value) return;

        const newAvailability = { ...availability };
        const updatedTime = new Date(value);

        const startTime = type === "start" ? updatedTime : newAvailability[day][index].start;
        const endTime = type === "end" ? updatedTime : newAvailability[day][index].end;

        if (startTime >= endTime) {
            Toast.fire({
                toast: true,
                icon: "warning",
                title: "Start time must be before the end time."
            });
            return;
        }

        newAvailability[day][index][type] = updatedTime;
        setAvailability(newAvailability);
    };

    const handleAddSlot = (day) => {
        const newAvailability = { ...availability };
        newAvailability[day].push({ start: new Date(), end: new Date() });
        setAvailability(newAvailability);
    };

    const handleDeleteSlot = (day, index) => {
        const newAvailability = { ...availability };
        newAvailability[day].splice(index, 1);
        setAvailability(newAvailability);
    };

    const handleCreate = async (id, key, inputValue) => {
        setIsLoading(true);
        const newOption = createOption(inputValue);
        await api.post('users/add-option', {key, item: {id: newOption.value, name: newOption.label}})
        setIsLoading(false);
        await fetchUser()
        setOptions(o=>({...o, [key]: [...o[key], newOption]}));
        setForm(f=>({...f, [id]: newOption.value}));
    };


    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <fieldset className={'form-fieldset weekly-editor'}>
                <legend className={'weekly-editor-legend'}><FormattedMessage id={"schedule.edit"} defaultMessage={'Edit your availability'}/></legend>

                <Row className={'weekly-editor-row top-row'}>
                    <Col style={{maxWidth: 120, width: 120, alignItems:'bottom'}}>Day</Col>
                    <Col>
                        <Table responsive className="table-weekly">
                            <thead>
                            <tr>
                                <th style={{width: 240, maxWidth: 240, minWidth: 240}}>Time Slots</th>
                                <th className={'table-weekly-td'}>Sport</th>
                                <th className={'table-weekly-td'}>Price (optional)</th>
                                <th className={'table-weekly-td'}>Comment</th>
                                <th className={'table-weekly-td'}>Location</th>
                                <th style={{width: 80, maxWidth: 80}}>Spots</th>
                                <th className={'weekly-editor-actions'}>&nbsp;</th>
                            </tr>
                            </thead>
                        </Table>
                        {/*<Row className={'mx-0'}>
                            <Col xs={4} style={{maxWidth: 550}}>Time Slots</Col>
                            <Col>Sport</Col>
                            <Col>Price (optional)</Col>
                            <Col>Comment</Col>
                            <Col>Location</Col>
                            <Col style={{maxWidth: 120}}>Spots</Col>
                            <Col className={'weekly-editor-actions'}>&nbsp;</Col>
                        </Row>*/}
                    </Col>
                </Row>
            {days.map((day) => (
                <Row className={'weekly-editor-row'}>
                    <Col style={{maxWidth: 120, width: 120}}>
                        <MDBox
                            display="flex"
                            justifyContent="flex-start"
                            alignItems="center"
                            mb={2}
                            lineHeight={1}
                        >
                            <Switch
                                color={'success'}
                                checked={availability[day].length > 0}
                                onChange={() => handleSwitchChange(day)}
                            />
                            <Typography>{day}</Typography>
                        </MDBox>
                    </Col>
                    <Col>

                        <Table responsive className="table-weekly">
                            <tbody>
                        {availability[day].map((slot, index) => (
                            <tr>
                                <td style={{width: 240, maxWidth: 240, minWidth: 240}}>
                                    <Stack
                                        key={index}
                                        direction="row"
                                        spacing={2}
                                        alignItems="center"

                                    >
                                        <TimePicker

                                            slotProps={{
                                                toolbar: {hidden: false, className: 'tp1-toolbar'},
                                                digitalClockItem: {className: 'tp1-clockItem'},
                                                digitalClockSectionItem: {className: 'tp1-sectionItem'},
                                                layout: {className: 'tp1-layout'},
                                                desktopPaper: {style: {width: 220}}
                                            }}
                                            className={'tp1'}
                                            label="Start"
                                            value={slot.start}
                                            ampm={false} formatDensity={'spacious'} minutesStep={15}
                                            onChange={(value) =>
                                                handleTimeChange(day, index, "start", value)
                                            }
                                        />
                                        <Box sx={{mx: 2}}> to </Box>
                                        <TimePicker
                                            slotProps={{
                                                toolbar: {hidden: false, className: 'tp1-toolbar'},
                                                digitalClockItem: {className: 'tp1-clockItem'},
                                                digitalClockSectionItem: {className: 'tp1-sectionItem'},
                                                layout: {className: 'tp1-layout'},
                                                desktopPaper: {style: {width: 220}}
                                            }}
                                            className={'tp1'}
                                            ampm={false} formatDensity={'spacious'} minutesStep={15}
                                            label="End"
                                            value={slot.end}
                                            onChange={(value) =>
                                                handleTimeChange(day, index, "end", value)
                                            }
                                        />
                                    </Stack>
                                </td>
                                <td className={'table-weekly-td-responsive'}>
                                    <div>
                                        <CreatableSelect
                                            noOptionsMessage={() => 'Type new item and press Enter to create'}
                                            isClearable
                                            placeholder={intl.formatMessage({id: "schedule.selectSport"}) + '...'}
                                            isDisabled={isLoading}
                                            isLoading={isLoading}
                                            onChange={(v) => setForm((f) => ({...f, activity: v?.value || null}))}
                                            onCreateOption={(inputValue) => handleCreate('activity', 'activities', inputValue)}
                                            options={options.activities}
                                            value={options.activities.find(a => form.activity === a.value)}
                                            styles={{
                                                valueContainer: (baseStyles) => ({...baseStyles, padding: 2}),
                                                indicatorsContainer: (baseStyles) => ({...baseStyles, width: 36}),
                                                container: (baseStyles) => ({...baseStyles, minHeight: 44, height: 44}),
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    minHeight: 44,
                                                    height: 44,
                                                    padding: 0
                                                }),
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <FormField variant={'outlined'}
                                                   label={intl.formatMessage({id: "schedule.price"})}
                                                   placeholder={intl.formatMessage({id: "schedule.price"})}
                                                   value={form.price}
                                                   inputProps={{
                                                       variant: 'outlined',
                                                       type: 'number',
                                                       value: form.price,
                                                       onChange: e => {
                                                           const price = parseInt(e.target.value);
                                                           console.log('price', price)
                                                           setForm(f => ({...f, price}))
                                                       }
                                                   }}/>
                                    </div>
                                    <div>
                                        <FormField variant={'outlined'}
                                                   label={intl.formatMessage({id: "common.comment"})}
                                                   placeholder={intl.formatMessage({id: "common.comment"})}
                                                   value={form.comment}
                                                   inputProps={{
                                                       variant: 'outlined',
                                                       type: 'text',
                                                       value: form.price,
                                                       onChange: e => {
                                                           const price = parseInt(e.target.value);
                                                           console.log('price', price)
                                                           setForm(f => ({...f, price}))
                                                       }
                                                   }}/>
                                    </div>
                                    <div>
                                        <CreatableSelect
                                            placeholder={intl.formatMessage({id: "forms.book.location"})}
                                            noOptionsMessage={() => 'Type new item and press Enter to create'}
                                            isClearable
                                            isDisabled={isLoading}
                                            isLoading={isLoading}
                                            onChange={(v) => setForm((f) => ({...f, location: v?.value || null}))}
                                            onCreateOption={(inputValue) => handleCreate('location', 'locations', inputValue)}
                                            options={options.locations}
                                            value={options.locations.find(a => form.location === a.value)}
                                            styles={{
                                                container: (baseStyles) => ({...baseStyles, minHeight: 44, height: 44}),
                                                control: (baseStyles) => ({...baseStyles, minHeight: 44, height: 44}),
                                            }}
                                        />
                                    </div>
                                </td>
                                    <td className={'table-weekly-td'}>
                                        <CreatableSelect
                                            noOptionsMessage={() => 'Type new item and press Enter to create'}
                                            isClearable
                                            placeholder={intl.formatMessage({id: "schedule.selectSport"}) + '...'}
                                            isDisabled={isLoading}
                                            isLoading={isLoading}
                                            onChange={(v) => setForm((f) => ({...f, activity: v?.value || null}))}
                                            onCreateOption={(inputValue) => handleCreate('activity', 'activities', inputValue)}
                                            options={options.activities}
                                            value={options.activities.find(a => form.activity === a.value)}
                                            styles={{
                                                valueContainer: (baseStyles) => ({...baseStyles, padding: 2}),
                                                indicatorsContainer: (baseStyles) => ({...baseStyles, width: 36}),
                                                container: (baseStyles) => ({...baseStyles, minHeight: 44, height: 44}),
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    minHeight: 44,
                                                    height: 44,
                                                    padding: 0
                                                }),
                                            }}
                                        />
                                    </td>
                                    <td className={'table-weekly-td'}>
                                        <FormField variant={'outlined'}
                                                   label={intl.formatMessage({id: "schedule.price"})}
                                                   placeholder={intl.formatMessage({id: "schedule.price"})}
                                                   value={form.price}
                                                   inputProps={{
                                                       variant: 'outlined',
                                                       type: 'number',
                                                       value: form.price,
                                                       onChange: e => {
                                                           const price = parseInt(e.target.value);
                                                           console.log('price', price)
                                                           setForm(f => ({...f, price}))
                                                       }
                                                   }}/>
                                    </td>
                                    <td className={'table-weekly-td'}>
                                        <FormField variant={'outlined'}
                                                   label={intl.formatMessage({id: "common.comment"})}
                                                   placeholder={intl.formatMessage({id: "common.comment"})}
                                                   value={form.comment}
                                                   inputProps={{
                                                       variant: 'outlined',
                                                       type: 'text',
                                                       value: form.price,
                                                       onChange: e => {
                                                           const price = parseInt(e.target.value);
                                                           console.log('price', price)
                                                           setForm(f => ({...f, price}))
                                                       }
                                                   }}/>

                                    </td>
                                    <td className={'table-weekly-td'}>
                                        <CreatableSelect
                                            placeholder={intl.formatMessage({id: "forms.book.location"})}
                                            noOptionsMessage={() => 'Type new item and press Enter to create'}
                                            isClearable
                                            isDisabled={isLoading}
                                            isLoading={isLoading}
                                            onChange={(v) => setForm((f) => ({...f, location: v?.value || null}))}
                                            onCreateOption={(inputValue) => handleCreate('location', 'locations', inputValue)}
                                            options={options.locations}
                                            value={options.locations.find(a => form.location === a.value)}
                                            styles={{
                                                container: (baseStyles) => ({...baseStyles, minHeight: 44, height: 44}),
                                                control: (baseStyles) => ({...baseStyles, minHeight: 44, height: 44}),
                                            }}
                                        />
                                    </td>
                                    <td style={{width: 80, maxWidth: 80}}>
                                        <Select options={options.maxUsers} value={createOption(form.maxUsers)}
                                                onChange={(v) => {
                                                    setForm(f => ({...f, maxUsers: v?.value || 1}))
                                                }}
                                                styles={{
                                                    container: (baseStyles) => ({
                                                        ...baseStyles,
                                                        minHeight: 44,
                                                        height: 44
                                                    }),
                                                    control: (baseStyles) => ({
                                                        ...baseStyles,
                                                        minHeight: 44,
                                                        height: 44
                                                    }),
                                                }}
                                        />
                                    </td>
                                    <td className={'weekly-editor-actions'}>
                                        <IconButton
                                            color="secondary"
                                            onClick={() => handleDeleteSlot(day, index)}
                                        >
                                            <Delete/>
                                        </IconButton>
                                    </td>
                            </tr>
                            ))}
                            </tbody>
                        </Table>
                        {availability[day].length > 0 && (
                            <MDButton
                                variant="contained"
                                color="info"
                                startIcon={<Add />}
                                style={{marginTop: 10}}
                                onClick={() => handleAddSlot(day)}
                            >
                                Add Slot
                            </MDButton>
                        )}

                    </Col>
                </Row>
            ))}


            </fieldset>

        </LocalizationProvider>
    );
};

export { WeeklyHours };
